import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PwaService {
  private updates = inject(SwUpdate);
  private transloco = inject(TranslocoService);
  private matSnackbar = inject(MatSnackBar);

  constructor() {
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        this.promptUser();
      }
    });
  }

  init() {
    if (this.updates.isEnabled) {
      setInterval(() => this.updates.checkForUpdate(), environment.swCheckTime * 1000);
    }
  }

  private promptUser() {
    this.matSnackbar
      .open(this.transloco.translate('PAGE_UPDATED_PLEASE_RELOAD'), this.transloco.translate('RELOAD_PAGE'))
      .afterDismissed()
      .subscribe(() => window.location.reload());
  }
}
